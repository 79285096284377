import React, { ReactElement } from 'react';

import { Checkmark } from '../svg/checkmark';

interface Props {
  text: string;
}

function BulletPoint({ text }: Props): ReactElement {
  return (
    <div className="flex items-center">
      <div>
        <Checkmark className="w-8 h-8 mr-4" />
      </div>
      <div className="md:text-xl">{text}</div>
    </div>
  );
}

export default BulletPoint;
