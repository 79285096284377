import React, { ReactElement } from 'react';
import { ISectionCompanyClaims } from 'typings/generated/contentful';

import CompanyClaim from '../widgets/company-claim';
import SectionContent from '../widgets/section-content';

interface Props {
  section: ISectionCompanyClaims;
}

function CompanyClaims({ section }: Props): ReactElement {
  return (
    <section className="bg-bg-light" id={section.fields.anchor}>
      <SectionContent
        className="py-8 space-y-16 md:space-x-8 md:space-y-0 md:justify-between md:flex-row"
        padding={false}
      >
        {section.fields.companyClaims?.map((claim) => (
          <CompanyClaim claim={claim} key={claim.sys.id} />
        ))}
      </SectionContent>
    </section>
  );
}

export default CompanyClaims;
