import cx from 'classnames';
import GatsbyImage from 'gatsby-image';
import React, { ReactElement, useCallback } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ISectionCompetitorComparison } from 'typings/generated/contentful';

import CTAButton from '../widgets/cta-button';
import SectionContent from '../widgets/section-content';
import SectionTitle from '../widgets/section-title';

interface Props {
  section: ISectionCompetitorComparison;
}

function CompetitorComparison({ section }: Props): ReactElement {
  const renderMark = useCallback((checked) => {
    if (checked) {
      return (
        <GatsbyImage
          alt="YES"
          className="w-8 h-8"
          fluid={getFluidImage(section.fields.imageCheckmark, {
            fm: null,
          })}
        />
      );
    }
    return (
      <GatsbyImage
        alt="NO"
        className="w-8 h-8"
        fluid={getFluidImage(section.fields.imageXMark, {
          fm: null,
        })}
      />
    );
  }, []);

  return (
    <section className="relative bg-bg-dark" id={section.fields.anchor}>
      {/* Background */}
      <div className="absolute top-0 bottom-0 left-0 right-0">
        <GatsbyImage
          className="w-full h-full"
          fluid={getFluidImage(section.fields.imageBackground, { w: null })}
        />
      </div>
      <SectionContent className="relative z-10">
        {/* Title */}
        <SectionTitle
          classNames={{
            preHeader: 'text-white',
            mainHeader: 'text-white',
            description: 'text-white',
            divider: 'bg-white',
          }}
          sectionData={section.fields.sectionData}
        />
        {/* Comparison */}
        <div className="grid w-full grid-cols-3 mt-16 text-white md:mt-32">
          {/* Empty first cell */}
          <div />
          {/* Title row */}
          {/* Own product header */}
          <div className="px-4 rounded-t md:h-32 md:px-8 bg-bg-light-3">
            {/* Own product image */}
            <div className="md:h-32 md:-mt-24">
              <GatsbyImage
                className="w-full mx-auto md:w-48 contain"
                fluid={getFluidImage(section.fields.imageOwnProduct)}
              />
            </div>
            {/* Own product logo */}
            <div className="flex items-center justify-center">
              <GatsbyImage
                className="w-full md:mx-auto md:w-32 contain"
                fluid={getFluidImage(section.fields.logoOwnProduct, {
                  fm: null,
                })}
              />
            </div>
          </div>
          {/* Competitor header */}
          <div className="px-2 md:h-32 md:px-8 ">
            {/* Competitor image */}
            <div className="md:h-32 md:-mt-24">
              <GatsbyImage
                className="w-full mx-auto md:w-48 contain"
                fluid={getFluidImage(section.fields.imageCompetitorProduct)}
              />
            </div>
            {/* Competitor name */}
            <div className="flex items-center justify-center px-2 pb-4 text-xl font-medium text-center md:text-2xl md:pb-0 md:h-24">
              {section.fields.nameCompetitorProduct}
            </div>
          </div>
          {/* Comparisons */}
          {section.fields.comparisons?.map((comparison, i, list) => {
            const last = i === list.length - 1;
            return (
              <>
                {/* Description */}
                <h3
                  className={cx(
                    'md:px-4 text-sm sm:text-base flex items-center py-4 md:text-xl',
                    { 'border-b border-white': !last }
                  )}
                >
                  {comparison.fields.description}
                </h3>
                {/* Own product */}
                <div
                  className={cx(
                    'bg-bg-light-3 flex justify-center items-center text-bg-light-3',
                    {
                      'h-16': last,
                      'rounded-b': last,
                      'border-b border-white': !last,
                    }
                  )}
                >
                  {renderMark(comparison.fields.myProduct)}
                </div>
                {/* Competitor product */}
                <div
                  className={cx('flex items-center justify-center', {
                    'border-b border-white': !last,
                  })}
                >
                  {renderMark(comparison.fields.competitorProduct)}
                </div>
              </>
            );
          })}
        </div>
        {/* CTA Button */}
        {section.fields.ctaButton && (
          <div className="mt-16 text-white">
            <CTAButton button={section.fields.ctaButton} />
          </div>
        )}
      </SectionContent>
    </section>
  );
}

export default CompetitorComparison;
