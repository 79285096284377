import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ISectionProductBenefits } from 'typings/generated/contentful';

import CTAButton from '../widgets/cta-button';
import ProductBenefit from '../widgets/product-benefit';
import SectionContent from '../widgets/section-content';
import SectionTitle from '../widgets/section-title';

interface Props {
  section: ISectionProductBenefits;
}

function ProductBenefits({ section }: Props): ReactElement {
  return (
    <section className="relative bg-bg-light" id={section.fields.anchor}>
      <div className="absolute top-0 bottom-0 left-0 hidden w-1/3 md:block bg-bg-light-2" />
      <SectionContent className="relative">
        <SectionTitle sectionData={section.fields.sectionData} />
        <div className="grid w-full grid-cols-1 mt-16 md:mt-24 gap-y-16 md:gap-x-8 md:grid-cols-2">
          <div className="w-full h-full max-h-40">
            <GatsbyImage
              className="w-full h-full contain"
              fluid={getFluidImage(section.fields.sectionImage)}
            />
          </div>
          <div className="relative flex flex-col -mt-12 space-y-12">
            <div className="absolute top-0 bottom-0 w-px ml-8 md:mt-0 bg-bg-dark" />
            {section.fields.productClaims?.map((claim) => (
              <ProductBenefit claim={claim} key={claim.sys.id} />
            ))}
          </div>
        </div>
        <CTAButton button={section.fields.ctaButton} className="mt-16" />
      </SectionContent>
    </section>
  );
}

export default ProductBenefits;
