import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { IProductClaim } from 'typings/generated/contentful';

interface Props {
  claim: IProductClaim;
}

function ProductBenefit({ claim }: Props): ReactElement {
  return (
    <div className="flex space-x-4">
      <div>
        <GatsbyImage
          className="w-16 h-16"
          fluid={getFluidImage(claim.fields.icon, { fm: null })}
        />
      </div>
      <div className="flex flex-col space-y-2">
        <h3 className="text-lg font-medium md:text-xl">{claim.fields.claim}</h3>
        <p className="md:text-lg">{claim.fields.description}</p>
      </div>
    </div>
  );
}

export default ProductBenefit;
