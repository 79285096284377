import GatsbyImage from 'gatsby-image';
import React, { ReactElement, useCallback } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { IHeroSection } from 'typings/generated/contentful';

import BulletPoint from '../widgets/bullet-point';
import CTAButton from '../widgets/cta-button';

interface Props {
  section: IHeroSection;
  isMobile: boolean;
}

function Hero({ section, isMobile }: Props): ReactElement {
  const renderBullets = useCallback(
    () => (
      <div className="space-y-2">
        {section.fields.productBullets?.map((text) => (
          <BulletPoint key={text} text={text} />
        ))}
      </div>
    ),
    [section]
  );

  return (
    <section>
      <div className="relative flex flex-col justify-center">
        {/* text content */}
        <div className="flex justify-center px-8 py-4 lg:py-16">
          <div className="w-screen max-w-screen-xl">
            <div className="flex flex-col items-center justify-center w-full h-full max-w-md mx-auto text-center lg:items-start lg:max-w-none lg:text-left">
              <div className="font-medium md:text-2xl">
                {section.fields.preHeader}
              </div>
              <h1 className="text-2xl font-bold md:text-3xl lg:mt-4 lg:text-5xl lg:w-3/5">
                {section.fields.mainHeader}
              </h1>
              <div className="hidden mt-8 text-xl font-medium break-normal md:text-2xl lg:block">
                <span className="p-4 text-white bg-blue-900">
                  {section.fields.subHeader}
                </span>
              </div>
              <div className="hidden mt-16 space-y-4 text-xl lg:block">
                {renderBullets()}
              </div>
              <CTAButton button={section.fields.ctaButton} className="mt-16" />
            </div>
          </div>
        </div>
        {/* background image */}
        <div
          className="top-0 bottom-0 left-0 right-0 h-48 pointer-events-none md:h-64 lg:absolute lg:h-full"
          style={{ zIndex: -1 }}
        >
          <GatsbyImage
            className="object-contain object-right h-full img-right-75"
            fluid={getFluidImage(section.fields.backgroundImage, {
              w: isMobile ? 768 : null,
            })}
          />
        </div>
        {/* mobile subheader */}
        <div className="block p-4 text-center text-white break-normal bg-bg-dark md:text-xl lg:hidden lg:text-2xl">
          <div className="max-w-xs m-auto">{section.fields.subHeader}</div>
        </div>
        {/* mobile bullets */}
        <div className="block px-8 py-4 space-y-4 md:text-lg md:py-8 lg:hidden">
          {renderBullets()}
        </div>
      </div>
      {/* mobile cta */}
      <div className="absolute bottom-0 left-0 right-0 lg:hidden bg-primary">
        {/* ToDo */}
      </div>
    </section>
  );
}

export default Hero;
