import React, { ReactElement } from 'react';
import { ISectionNotificationBar } from 'typings/generated/contentful';

import ContentfulRichText from '../widgets/contentful-rich-text';

interface Props {
  section: ISectionNotificationBar;
}

function Notification({ section }: Props): ReactElement {
  return (
    <div className="flex items-center justify-center w-full p-2 text-sm text-center text-white sm:text-base bg-cta-primary">
      <ContentfulRichText document={section.fields.content} />
    </div>
  );
}

export default Notification;
