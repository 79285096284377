import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ISectionBulletPoints } from 'typings/generated/contentful';

import BulletPoint from '../widgets/bullet-point';
import SectionContent from '../widgets/section-content';
import SectionTitle from '../widgets/section-title';

interface Props {
  section: ISectionBulletPoints;
}

function BulletPoints({ section }: Props): ReactElement {
  return (
    <section id={section.fields.anchor}>
      <SectionContent>
        <SectionTitle sectionData={section.fields.sectionData} />
        <div className="grid w-full grid-cols-1 mt-16 md:mt-24 gap-y-16 md:gap-x-8 md:grid-cols-2">
          <div className="w-full h-full max-h-30">
            <GatsbyImage
              className="w-full h-full contain"
              fluid={getFluidImage(section.fields.sectionImage, { q: null })}
            />
          </div>
          <div className="flex flex-col space-y-8 md:py-8">
            {section.fields.bullets?.map((text) => (
              <BulletPoint key={text} text={text} />
            ))}
          </div>
        </div>
      </SectionContent>
    </section>
  );
}

export default BulletPoints;
