import cx from 'classnames';
import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ICompanyClaims, IProductClaim } from 'typings/generated/contentful';

interface Props {
  claim: ICompanyClaims | IProductClaim;
  iconSize: 'small' | 'regular';
}

function Claim({ claim, iconSize }: Props): ReactElement {
  return (
    <div className="flex flex-col items-center flex-1 max-w-md">
      <div>
        <GatsbyImage
          className={cx('contain', {
            'w-32 h-32': iconSize === 'regular',
            'w-24 h-24': iconSize === 'small',
          })}
          fluid={getFluidImage(claim.fields.icon, { fm: null })}
        />
      </div>
      <h3 className="mt-8 text-lg font-medium">{claim.fields.claim}</h3>
      <p className="px-8 mt-4 text-center">{claim.fields.description}</p>
    </div>
  );
}

export default Claim;
