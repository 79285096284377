import React, { ReactElement } from 'react';
import { ISectionYouCanTrustUs } from 'typings/generated/contentful';

import Claim from '../widgets/claim';
import CTAButton from '../widgets/cta-button';
import SectionContent from '../widgets/section-content';
import SectionTitle from '../widgets/section-title';

interface Props {
  section: ISectionYouCanTrustUs;
}

function YouCanTrustUs({ section }: Props): ReactElement {
  return (
    <section id={section.fields.anchor}>
      <SectionContent>
        <SectionTitle sectionData={section.fields.sectionData} />
        <div className="flex flex-col justify-between mt-16 space-y-16 md:mt-24 md:space-y-0 md:space-x-8 md:flex-row">
          {section.fields.companyClaims?.map((claim) => (
            <Claim claim={claim} iconSize="regular" key={claim.sys.id} />
          ))}
        </div>
        <CTAButton button={section.fields.ctaButton} className="mt-16" />
      </SectionContent>
    </section>
  );
}

export default YouCanTrustUs;
