import React, { ReactElement } from 'react';
import { ISectionProductIntro } from 'typings/generated/contentful';

import Claim from '../widgets/claim';
import CTAButton from '../widgets/cta-button';
import ProductVideo from '../widgets/product-video';
import SectionContent from '../widgets/section-content';
import SectionTitle from '../widgets/section-title';

interface Props {
  section: ISectionProductIntro;
}

function ProductIntro({ section }: Props): ReactElement {
  return (
    <section id={section.fields.anchor}>
      <SectionContent>
        <SectionTitle sectionData={section.fields.sectionData} />
        <ProductVideo
          productImage={section.fields.productImage}
          productVideo={section.fields.productVideo}
        />
        <div className="flex flex-col mt-16 space-y-16 md:space-y-0 md:flex-row">
          {section.fields.productClaims?.map((claim) => (
            <Claim claim={claim} iconSize="small" key={claim.sys.id} />
          ))}
        </div>
        <CTAButton button={section.fields.ctaButton} className="mt-16" />
      </SectionContent>
    </section>
  );
}

export default ProductIntro;
