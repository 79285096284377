import cx from 'classnames';
import { Asset } from 'contentful';
import GatsbyImage from 'gatsby-image';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';

interface Props {
  productImage: Asset | undefined;
  productVideo: Asset | undefined;
}

function ProductVideo({ productImage, productVideo }: Props): ReactElement {
  const [playingVideo, setPlayingVideo] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (playingVideo && videoRef.current) {
      videoRef.current.play();
    }
  }, [playingVideo, videoRef]);

  const renderImage = useCallback(
    () => (
      <div
        className={cx(
          'w-full h-full max-w-xl outline-none cursor-pointer mx-auto focus:outline-none',
          {
            hidden: playingVideo,
          }
        )}
        onClick={() => setPlayingVideo(true)}
      >
        <GatsbyImage
          className="w-full h-full contain"
          fluid={getFluidImage(productImage, { q: null })}
        />
      </div>
    ),
    [productImage, playingVideo]
  );

  const renderVideo = useCallback(
    () => (
      <div
        className={cx('w-full h-full  max-w-xl flex items-center mx-auto', {
          hidden: !playingVideo,
        })}
      >
        <video
          className="rounded focus:outline-none"
          controls
          controlsList="nodownload"
          height="100%"
          ref={videoRef}
          width="100%"
        >
          <source
            src={productVideo?.fields.file.url}
            type={productVideo?.fields.file.contentType}
          />
        </video>
      </div>
    ),
    [productVideo, playingVideo]
  );

  return (
    <div className="w-full mt-8 h-203px md:h-324px">
      {renderVideo()}
      {renderImage()}
    </div>
  );
}

export default ProductVideo;
