import { Entry } from 'contentful';
import React, { ReactElement, useCallback, useMemo } from 'react';
import BulletPoints from 'src/components/sections/bullet-points';
import CompanyClaims from 'src/components/sections/company-claims';
import CompetitorComparison from 'src/components/sections/competitor-comparison';
import Footer from 'src/components/sections/footer';
import Hero from 'src/components/sections/hero';
import Navbar from 'src/components/sections/navbar';
import Notification from 'src/components/sections/notification';
import ProductBenefits from 'src/components/sections/product-benefits';
import ProductIntro from 'src/components/sections/product-intro';
import Testimonials from 'src/components/sections/testimonials';
import YouCanTrustUs from 'src/components/sections/you-can-trust-us';
import CTAButton from 'src/components/widgets/cta-button';
import Favicon from 'src/components/widgets/favicon';
import SEO from 'src/components/widgets/seo';
import { useLayout } from 'src/util/useLayout';
import {
  ICta,
  IHeroSection,
  ILandingPage,
  ISectionBulletPoints,
  ISectionCompanyClaims,
  ISectionCompetitorComparison,
  ISectionFooter,
  ISectionHeader,
  ISectionNotificationBar,
  ISectionProductBenefits,
  ISectionProductIntro,
  ISectionTestimonials,
  ISectionYouCanTrustUs,
  IStaticPages,
} from 'typings/generated/contentful';

interface Props {
  location: Location;
  pageContext: {
    reactContext: {
      staticPages: IStaticPages[];
    };
    landingPage: ILandingPage;
  };
}

export default function LandingPage(props: Props): ReactElement {
  const {
    location,
    pageContext: { landingPage },
  } = props;
  const layout = useLayout();

  const renderSection = useCallback(
    (section: Entry<unknown>, isMobile: boolean) => {
      switch (section.sys.contentType.sys.id) {
        case 'sectionHeader':
          return <Navbar section={section as ISectionHeader} />;
        case 'sectionNotificationBar':
          return <Notification section={section as ISectionNotificationBar} />;
        case 'heroSection':
          return <Hero isMobile={isMobile} section={section as IHeroSection} />;
        case 'sectionCompanyClaims':
          return <CompanyClaims section={section as ISectionCompanyClaims} />;
        case 'sectionYouCanTrustUs':
          return <YouCanTrustUs section={section as ISectionYouCanTrustUs} />;
        case 'sectionBulletPoints':
          return <BulletPoints section={section as ISectionBulletPoints} />;
        case 'sectionTestimonials':
          return <Testimonials section={section as ISectionTestimonials} />;
        case 'sectionProductBenefits':
          return (
            <ProductBenefits section={section as ISectionProductBenefits} />
          );
        case 'sectionProductIntro':
          return <ProductIntro section={section as ISectionProductIntro} />;
        case 'sectionFooter':
          return <Footer section={section as ISectionFooter} />;
        case 'sectionCompetitorComparison':
          return (
            <CompetitorComparison
              section={section as ISectionCompetitorComparison}
            />
          );
        default:
          return null;
      }
    },
    []
  );

  const stickyCTA: ICta | undefined = useMemo(
    () =>
      landingPage.fields.contentMobile?.find(
        (content) => content.sys.contentType.sys.id === 'cta'
      ) as ICta | undefined,
    [landingPage.fields.contentMobile]
  );

  return (
    <div className="flex flex-col text-primary">
      <Favicon icon={landingPage.fields.favicon} />
      <SEO canonical={location.href} seo={landingPage.fields.seoInformation} />
      {layout !== 'mobile' && (
        <div className="hidden lg:block">
          {landingPage.fields.content?.map((section) =>
            renderSection(section, false)
          )}
        </div>
      )}
      {layout !== 'desktop' && (
        <div className="lg:hidden">
          {landingPage.fields.contentMobile?.map((section) =>
            renderSection(section, true)
          )}
          {/* push content above so cta does not hide page content */}
          {stickyCTA && <div className="h-32" />}
          {/* mobile cta */}
          {stickyCTA && (
            <div
              className="fixed bottom-0 left-0 right-0 z-10 px-8 py-4 text-white bg-bg-dark"
              data-aos="slide-up"
              data-aos-offset="200"
            >
              <CTAButton button={stickyCTA} smaller />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
